function checkIOSVersion() {
  const agent = window.navigator.userAgent;
  const start = agent.indexOf('OS ');

  if ((~agent.indexOf('iPhone') || ~agent.indexOf('iPad')) && ~start) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
  }

  return 0;
}

export default checkIOSVersion;
